const $ = require('jquery');

$(() => {
 //$('.c-blockSlider__wrapper').slick({
 // setting-name: setting-value
 //});
 //$('.slicktest').slick({
 // infinite: true,
 // slidesToShow: 3,
 // slidesToScroll: 3,
 // autoplay: true,
 // autoplaySpeed: 2000,
 //});
 $('.c-blockSlider__wrapper').slick({
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 8000,
  responsive: [
   {
	breakpoint: 768,
	settings: {
	 centerMode: true,
	 centerPadding: '0px',
	 slidesToShow: 1,
	 arrows: false,
	 dots: true,
	 autoplay: false,
	 speed: 500,
	 fade: true,
	 cssEase: 'linear'
	}
   },
   {
	breakpoint: 480,
	settings: {
	 centerMode: true,
	 centerPadding: '0px',
	 slidesToShow: 1,
	 arrows: false,
	 dots: true,
	 autoplay: false,
	 speed: 500,
	 fade: true,
	 cssEase: 'linear'
	}
   }
  ]
 });
 $('.c-siteHeaderMedium__top').click(function () {

  $('.c-siteHeaderMedium__megamenu').show();
 });

 $('.c-siteHeaderMedium__megamenuTop').click(function () {

  $('.c-siteHeaderMedium__megamenu').hide();
 });

 $('.c-siteHeaderMobile__top > .c-siteHeaderMobile__menu').click(function () {
  $(window).scrollTop(0);
  $('.c-siteHeaderMobile__top').hide();
  $('.c-siteHeaderMobile__top-collapse').attr('style', 'display:flex');

  $('.c-siteHeaderMobile__menuList').slideDown({
   start: function () {
	$(this).css({
	 display: 'flex',
	});
	$('html').css('overflow', 'hidden');
	$('.c-siteHeaderMobile').css({
	 position: 'relative',
	});
   },
  });
 });

 $('.c-siteHeaderMobile__top-collapse > .c-siteHeaderMobile__menu').click(function () {
  $('.c-siteHeaderMobile__top-collapse').hide();
  $('.c-siteHeaderMobile__top').attr('style', 'display:flex');

  $('.c-siteHeaderMobile__menuList').slideUp();
  $('.c-siteHeaderMobile__menuAllSites').slideUp();
  $('html').css('overflow', '');
 });

 $('#btnAllSites').click(function () {
  $(window).scrollTop(0);
  $('.c-siteHeaderMobile__menuList').slideUp();

  $('.c-siteHeaderMobile__menuAllSites').slideUp({
   start: function () {
	$(this).css({
	 display: 'block',
	});
	$('.c-siteHeaderMobile').css({
	 position: 'relative',
	});
   },
  });
 });

 $('#btnBackToMenu').click(function () {

  $(window).scrollTop(0);
  $('.c-siteHeaderMobile__menuAllSites').slideUp();

  $('.c-siteHeaderMobile__menuList').slideDown({
   start: function () {
	$(this).css({ display: 'block', });
	$('.c-siteHeaderMobile').css({ position: 'relative', });
   },
  });
 });

 $('.menu-bottom-search').click(function (e) {
  e.preventDefault();
  $('.searchImg').hide();
  $('.c-siteHeaderMobile__searchForm').show({
   start: function () {
	$(this).css({
	 display: 'flex',
	});

   },
  });
 });

 // $(window).scroll(() => {
 // 	//alert("hej");
 // 	const scroll = $(window).scrollTop();
 // 	const header = $('.c-siteHeaderMobile');

 // 	// // Shrink header
 // 	// if (scroll > 50) {
 // 	// 	header.addClass('shrink');
 // 	// } else {
 // 	// 	header.removeClass('shrink');
 // 	// }

 // 	// Hide header
 // 	//alert(header.outerHeight())
 // 	if (scroll > 800 - header.outerHeight()) {
 // 		header.css('top', (scroll));
 // 	} else {
 // 		header.css('top', 0);
 // 	}
 // });	
 var position = $(window).scrollTop();
 $(window).scroll(function (event) {
  //alert(position);
  if ($(window).width() < 576) {
   //if (event.originalEvent.wheelDelta >= 0) {
   //alert('Scroll up');
   const scroll = $(window).scrollTop();
   const header = $('.c-siteHeaderMobile');
   $('.c-siteHeaderMobile').show();
   //alert(event.originalEvent.wheelDelta)
   // if (scroll > position) {
   // 	alert('dwn');
   // } else {
   // 	alert('up');
   // }

   if (scroll > 3800 - header.outerHeight()) {
	//alert("hej");
	if (scroll < position) {
	 $('.c-siteHeaderMobile__top-collapse').hide();
	 $('.c-siteHeaderMobile__top').attr('style', 'display:flex');

	 $('.c-siteHeaderMobile__menuList').css('display', 'none');
	 $('.c-siteHeaderMobile__menuAllSites').hide();
	 header.attr('style', 'position: fixed');
	}
	else {
	 //alert('Scroll down');
	 header.attr('style', 'position: relative');
	}
   } else {
	//header.css('top', 0);
	//alert("else");
	//alert(scroll);
	if (scroll < 5) {
	 header.attr('style', 'position: relative');
	}
   }

   position = scroll;
   // }
   // else {
   // 	//alert('Scroll down');
   // 	$('.c-siteHeaderMobile').hide();
   // }
  }
 });

 $('.c-article__sidebar ul li').each(function () {
  if ($(this).hasClass('current-page')) {
   $(this).find('.c-article__icon-down').addClass("opened").attr("src", "/src/dist/images/vanstermenypil-svart-upp.svg");
  }
 });

 $('.c-article__icon-down').click(function () {
  if ($(this).hasClass('opened')) {
   $(this).removeClass('opened');
   if ($(this).hasClass('current')) {
	$(this).attr("src", '/src/dist/images/vanstermenypil-svart-ned.svg');
   }
   else {
	$(this).attr("src", '/src/dist/images/vanstermenypil-ny.svg');
   }

   $(this).next().slideUp();
  }
  else {
   if ($(this).hasClass('current')) {
	$(this).attr("src", '/src/dist/images/vanstermenypil-svart-upp.svg');
   }
   else {
	$(this).attr("src", '/src/dist/images/vanstermenypil-ny-upp.svg');
   }

   //$(this).attr("src", '/src/dist/images/enkel-pil-upp.svg');
   $(this).addClass('opened').next().slideDown();
  }
 });



 $('.c-siteHeaderMobile__icon-down').click(function () {
  var color = $(this).attr('data-color');
  if ($(this).hasClass('opened')) {
   $(this).removeClass('opened');
   if ($(this).hasClass('current-page')) {
	$(this).attr("src", '/src/dist/images/Arrow_white_up.svg');
   }
   else {
	$(this).attr("src", '/src/dist/images/Arrow_' + color + '_down.svg');
   }

   $(this).next().slideUp();
  }
  else {
   if ($(this).hasClass('current')) {
	$(this).attr("src", '/src/dist/images/Arrow_white_up.svg');
   }
   else {
	$(this).attr("src", '/src/dist/images/Arrow_' + color + '_up.svg');
   }

   //$(this).attr("src", '/src/dist/images/enkel-pil-upp.svg');
   $(this).addClass('opened').next().slideDown();
  }
 });

});

$('ul.c-article__filter-ul-list > li.click').on('click', function (e) {
 e.preventDefault();

 e.stopPropagation();
 if (!$(this).hasClass('current')) {
  var found;
  var data_id = $(this).attr('data-id');
  $('ul.c-article__filter-ul-list > li.current').removeClass('current');
  $(this).addClass('current');

  $('.c-article__filter-item-list').each(function () {
   var tax_id = $(this).attr('data-id').split('+');
   found = 0;
   for (var i = 0; i < tax_id.length; i++) {
	if (tax_id[i] == data_id) {
	 found = 1;
	}
   }
   if (found == 1 || data_id == 'all') {
	$(this).show();
   }
   else {
	$(this).hide();
   }
  });
 }
});

$('.btnBackToTop').click(function () {
 $("html, body").animate({ scrollTop: 0 }, "slow");
 return false;
});

$('.submit').click(function () {
 var form = $(this).parents('form:first');
 form.submit();
});
