import '../stylesheets/style.scss';
import './custom/slick';
import './custom/main.js';
import 'paroller.js';
import './vendor/wow';
import './custom/scroll';
import './custom/video';
import './custom/slider';
import './custom/lazyload';


function requireAll(r) {
 r.keys().forEach(r);
}
requireAll(require.context('../images', true, /\.(gif|ico|jpe?g|png|svg|webp)$/));

const $ = require('jquery');

$('.paroller-right').paroller({
	factor: 0.08,
	type: 'foreground',
	direction: 'horizontal',
});

$('.paroller-left').paroller({
	factor: -0.08,
	type: 'foreground',
	direction: 'horizontal',
});

