const $ = require('jquery');

$('.c-blockVideo #play').click(() => {
 $('.c-blockVideo__overlay').remove();
 const code = $('.c-blockVideo').data().code;

	$('.c-blockVideo').append(
		`<iframe id="iframe" src="https://www.youtube.com/embed/${code}?autoplay=1&showinfo=0&rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
	);
});
