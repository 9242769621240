const $ = require('jquery');

/**
 * Slider layout
 */
function fixMargins(slider) {
	$(slider).css('padding-left', `${$('.container').css('margin-left')}`);
	$(slider).css('scroll-padding-left', `${$('.container').css('margin-left')}`);
}

$(() => {
	const sliders = $('.c-blockNews__wrapper, .c-blockCalendar__wrapper');

	sliders.each((index, slider) => {
		fixMargins(slider);

		$(window).resize(() => {
			setTimeout(() => {
				fixMargins(slider);
			}, 20);
		});

		const slides = $(slider).find('li');
		let animating = false;

		// Show previous button
		$(slider).scroll(e => {
			const scrollLeft = $(slider).scrollLeft();
			const maxScrollLeft = $(slider).get(0).scrollWidth - $(slider).get(0).clientWidth;
			const percent = (scrollLeft / maxScrollLeft) * 100;

			if (percent > 80 && !$(slider).hasClass('no-repeat')) {
				$(slider).append(slides.clone());
			}

			if ($(e.target).scrollLeft() > 0) {
				$(e.target)
					.parent().parent()
					.find('nav svg:first-of-type')
					.addClass('show');
			} else {
				$(e.target)
					.parent().parent()
					.find('nav svg:first-of-type')
					.removeClass('show');
			}
		});

	 
		// Previous slide
	 $(slider)
	  .parent().parent()
			.find('nav svg:first-of-type')
			.click(() => {
			 let width = 300;

				if ($(slider).hasClass('c-blockNews__wrapper')) {
					width = 500;
				}

				if (!animating) {
					animating = true;
					$(slider).css('scroll-snap-type', 'none');
					$(slider).animate(
						{
							scrollLeft: `-=${width}`,
						},
						400,
						() => {
							$(slider).css('scroll-snap-type', 'x mandatory');
							animating = false;
						}
					);
				}
			});

		// Next slide
		$(slider)
		 .parent().parent()
			.find('nav svg:last-of-type')
			.click(() => {
				let width = 300;

				if ($(slider).hasClass('c-blockNews__wrapper')) {
					width = 500;
				}

				if (!animating) {
					animating = true;
					$(slider).css('scroll-snap-type', 'none');
					$(slider).animate(
						{
							scrollLeft: `+=${width}`,
						},
						400,
						() => {
							$(slider).css('scroll-snap-type', 'x mandatory');
							animating = false;
						}
					);
				}
			});
	});
});
