const $ = require('jquery');

$(() => {
 if ($('body.home').length > 0) {
  $(window).scroll(() => {
   const scroll = $(window).scrollTop();
   const firstElement = $('.c-blockCalendar').get(0);
   const secondElement = $('.c-blockCalendar').get(1);

   if (scroll > $(firstElement).offset().top - $(firstElement).outerHeight() / 2) {
	if (scroll < $(secondElement).offset().top - $(secondElement).outerHeight() / 2) {
	 $('body').addClass('bg-leather');
	} else {
	 $('body').removeClass('bg-leather');
	}
   } else {
	$('body').removeClass('bg-leather');
   }
  });
 }
});
